import React, { useMemo, memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary//SpriteIcon';
import JsonLD, { JSON_LD_TYPES } from 'components/Globals/JsonLD';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import routesWithTitle from 'constants/routesWithTitle';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';

import classes from './Breadcrumbs.module.scss';
import Typography from '../Typography';

const trackingData = {
  section: SECTIONS.BREADCRUMBS,
};

const BreadcrumbLink = memo(
  ({ index, homepageLinkProps, path, href, title, isLastItem, truncateTitle = false, overlay }) => {
    const { t } = useTranslation('NS_APP_GLOBALS');
    const linkTitle = t(title) || title;

    if (index === 0) {
      return (
        <LinkButton
          isLink
          variant="text"
          {...homepageLinkProps}
          styles={{
            root: classnames(classes.listItem, {
              [classes.overlayItem]: !!overlay,
              [classes.listItem_lastItem]: !!isLastItem,
            }),
          }}
          title={linkTitle}
          trackingData={{ ...trackingData, component: COMPONENTS.HOME, meta: { index, path } }}
        >
          <SpriteIcon icon="home" size="12" />
        </LinkButton>
      );
    }

    if (isLastItem || !path) {
      return (
        <Typography
          className={classnames(classes.lastItem, {
            [classes.listItem_lastItem]: !!isLastItem,
          })}
          color={overlay ? 'white' : 'secondary'}
          size="12"
          {...(isLastItem && { weight: 'medium' })}
          truncate
        >
          {linkTitle}
        </Typography>
      );
    }

    return (
      <LinkButton
        isLink
        variant="text"
        as={href ? path : undefined}
        href={href || path}
        styles={{
          root: classnames(classes.listItem, {
            [classes.overlayItem]: !!overlay,
            [classes.listItem_lastItem]: !!isLastItem,
          }),
        }}
        title={linkTitle}
        trackingData={{ ...trackingData, meta: { index, path } }}
      >
        {truncateTitle ? (
          '...'
        ) : (
          <Typography
            className={classes.title}
            {...(isLastItem && { weight: 'medium' })}
            size="12"
            color={overlay ? 'white' : 'secondary'}
            truncate
          >
            {linkTitle}
          </Typography>
        )}
      </LinkButton>
    );
  },
);

const Breadcrumbs = ({ data: links, maxItems, overlay = false }) => {
  const router = useRouter();
  const { navigate, entityType } = usePageContext();
  const homepageLinkProps = useMemo(() => navigate.getLinkProps({ onlyLinkProps: true }), [navigate]);

  if ((!entityType && router.pathname === routesWithTitle.HOME.path) || !links?.length) {
    return null;
  }

  const count = links?.length;

  return (
    <div className={classes.breadCrumbs}>
      <nav className={classes.breadCrumbs__nav}>
        <ul
          className={classnames(classes.breadCrumbs__nav_items, {
            [classes.breadCrumbs__nav_items_overlay]: !!overlay,
          })}
        >
          {links?.map((link, index) => (
            <li
              key={index}
              className={classnames(classes.breadCrumbs__nav_items_item, {
                [classes.breadCrumbs__nav_items_item_overlay]: !!overlay,
              })}
            >
              <BreadcrumbLink
                index={index}
                path={link.as || link.path}
                href={link.href}
                title={link.title}
                homepageLinkProps={homepageLinkProps}
                isLastItem={index === count - 1}
                {...(maxItems > 0 && { truncateTitle: count - index > maxItems })}
                overlay={overlay}
              />
            </li>
          ))}
        </ul>
      </nav>
      <JsonLD type={JSON_LD_TYPES.BREADCRUMB} data={links} />
    </div>
  );
};

Breadcrumbs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.shape({}),
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

export default Breadcrumbs;
